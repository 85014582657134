import React from "react";
import { Routes, Route } from "react-router-dom";

import { PageLayout, PageNotFound, ChatGPTPage, TicketsPage, AdminDashboardPage, HelpCenterPage } from "../pages";
import { QRGen, SignatureGen } from "../components/Tools";

import links from "./links";
import { ProtectedAdminRoutes, ProtectedRoutes } from "./ProtectedRoutes";
import { ManageEmployee } from "../pages/ManageEmployee";

export const Router = () => {
  return (
    <Routes>
      <Route path={links.home} element={<PageLayout />}>
        <Route index element={<HelpCenterPage />} />
        <Route path={links.qrCodeGen} element={<QRGen />} />
        <Route path={links.signatureGen} element={<SignatureGen />} />
        <Route element={<ProtectedRoutes />}>
          <Route path={links.chat} element={<ChatGPTPage />} />
          <Route path={links.tickets} element={<TicketsPage />} />
          <Route path={links.singleTicket} element={<TicketsPage single={true} />} />
          <Route path={links.createTicket} element={<TicketsPage create={true} />} />
          <Route path={links.employee} element={<ManageEmployee />} />
        </Route>
        <Route path={links.admin} element={<ProtectedAdminRoutes />}>
          <Route path={links.dashboard} element={<AdminDashboardPage />} />
        </Route>
        <Route path={links.helpcenter} element={<HelpCenterPage />} />
        <Route path={links.helpcenterSections} element={<HelpCenterPage />} />
        <Route path={links.helpcenterApps} element={<HelpCenterPage />} />
        <Route path={links.helpcenterArticles} element={<HelpCenterPage />} />
        <Route path={links.pageNotFound} element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
