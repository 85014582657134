import React, { useState } from "react";
import { Form, Container, Row, Col, Stack } from "react-bootstrap";

import BrandButton from "../BrandButton";
import useUserStore from "../../store/useUserStore";
import { useNotification } from "../../hooks/useNotification";
import useTickets from "../../hooks/useTickets";

const NewTicketForm = ({ onCancel }) => {
  const { showNotification } = useNotification();
  const { userProfile } = useUserStore();
  const { openTicket } = useTickets();

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [priority, setPriority] = useState("low");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newTicketData = {
      state: "new",
      title,
      text,
      userId: userProfile.id,
      priority,
      conversation_history: [
        {
          timestamp: new Date(),
          senderId: userProfile.id,
          message: text,
          senderName: userProfile.displayName,
        },
      ],
    };
    const result = await openTicket(newTicketData);

    console.log("result", result);
    if (result?.error) {
      showNotification({
        title: "New ticket creation error",
        message: result.error,
      });
      return;
    }

    try {
      setTitle("");
      setText("");
      setPriority("low");
      showNotification({
        title: "New ticket created.",
        type: "success",
      });

      onCancel();
    } catch (error) {
      showNotification({
        title: "New ticket creation error",
        message: error,
      });
    }
  };

  if (userProfile)
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <h1>Create new Ticket</h1>
            <Form onSubmit={handleSubmit}>
              <Stack gap={4}>
                <Form.Group controlId="formTitle">
                  <Form.Label>Short description of the issue</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Short description of the issue"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formText">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Describe the issue in detail"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formPriority">
                  <Form.Label>Priority</Form.Label>
                  <Form.Control as="select" value={priority} onChange={(e) => setPriority(e.target.value)} required>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className={`d-flex justify-content-around align-items-center p-4`} controlId="formMessage">
                  <BrandButton variant="primary" type="submit">
                    Create
                  </BrandButton>
                  <BrandButton variant="primary" type="reset">
                    Clean
                  </BrandButton>
                  <BrandButton variant="primary" type="button" onClick={onCancel}>
                    Cancel
                  </BrandButton>
                </Form.Group>
              </Stack>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  else return <Row className="w-100 h-100 d-felx justify-content-center align-items-center">Loading...</Row>;
};

export default NewTicketForm;
