// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandButtonAnimated_container__wM-QB {
    position: relative;
    display: flex;
    border-radius: 9999px;
    border: 1px solid transparent;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.1s;
    overflow: visible;
    padding: 1px;
    width: fit-content;
    flex-direction: column;
    gap: 10px;
  }
  
  .BrandButtonAnimated_container__wM-QB:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .BrandButtonAnimated_container__wM-QB:hover .BrandButtonAnimated_innerBorder__HwQkg, .BrandButtonAnimated_container__wM-QB:hover .BrandButtonAnimated_content__uNQeG {
    background: rgba(0, 0, 0, 0.35);
  }

  .BrandButtonAnimated_content__uNQeG {
    width: auto;
    color: white;
    z-index: 10;
    background: black;
    padding: 8px 16px;
    border-radius: inherit;
  }
  
  .BrandButtonAnimated_motionDiv__t9Hbh {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(2px);
    z-index: 0;
    border-radius: inherit;
  }
  
  .BrandButtonAnimated_innerBorder__HwQkg {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: black;
    border-radius: 100px;
    z-index: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/BrandButtonAnimated/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,6BAA6B;IAC7B,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;IAC9B,2BAA2B;IAC3B,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,oBAAoB;IACpB,UAAU;EACZ","sourcesContent":[".container {\n    position: relative;\n    display: flex;\n    border-radius: 9999px;\n    border: 1px solid transparent;\n    justify-content: center;\n    align-items: center;\n    background: rgba(0, 0, 0, 0.2);\n    transition: background 0.1s;\n    overflow: visible;\n    padding: 1px;\n    width: fit-content;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .container:hover {\n    background: rgba(0, 0, 0, 0.1);\n  }\n  \n  .container:hover .innerBorder, .container:hover .content {\n    background: rgba(0, 0, 0, 0.35);\n  }\n\n  .content {\n    width: auto;\n    color: white;\n    z-index: 10;\n    background: black;\n    padding: 8px 16px;\n    border-radius: inherit;\n  }\n  \n  .motionDiv {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    filter: blur(2px);\n    z-index: 0;\n    border-radius: inherit;\n  }\n  \n  .innerBorder {\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    right: 2px;\n    bottom: 2px;\n    background: black;\n    border-radius: 100px;\n    z-index: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandButtonAnimated_container__wM-QB`,
	"innerBorder": `BrandButtonAnimated_innerBorder__HwQkg`,
	"content": `BrandButtonAnimated_content__uNQeG`,
	"motionDiv": `BrandButtonAnimated_motionDiv__t9Hbh`
};
export default ___CSS_LOADER_EXPORT___;
