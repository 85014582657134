import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Stack, Image, Collapse } from "react-bootstrap";
import { ArrowsExpand, ArrowsCollapse } from "react-bootstrap-icons";

import style from "./index.module.css";
import default_avatar from "../../../static/images/default_avatar.png";
import BrandButtonAnimated from "../../BrandButtonAnimated";
import MarkdownContent from "../../MarkdownContent";

import useThemeStore from "../../../store/themeStore";

const HelpSectionItem = ({ section }) => {
  const { title, description, icon, programs, slug } = section;
  const [open, setOpen] = useState(false);

  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);

  const navigate = useNavigate();

  return (
    <>
      <Stack direction="horizontal" className={isWhiteMode ? style.card : style.cardDark} gap={5} onClick={() => setOpen(!open)}>
        <Image
          width={40}
          height={40}
          src={icon?.data ? process.env.REACT_APP_STRAPI_URL + icon?.data?.attributes.url : default_avatar}
          alt={title}
          className={style.icon}
        />
        <Row className={style.content}>
          <h5 className="">{title}</h5>
          <MarkdownContent>{description ?? ""}</MarkdownContent>
        </Row>
        {programs.data.length > 0 && (open ? <ArrowsCollapse /> : <ArrowsExpand />)}
      </Stack>
      {programs.data.length > 0 && (
        <Collapse in={open} className={style.collapse}>
          <div className={style.programs}>
            {programs?.data?.map((program) => (
              <BrandButtonAnimated
                key={program.id}
                variant="outline-primary"
                size="sm"
                onClick={() => navigate(`/helpcenter/${slug}/${program.attributes.slug}`)}
                target="_blank"
                rel="noreferrer"
                style={{ width: "100%" }}
              >
                {program.attributes.Name}
              </BrandButtonAnimated>
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
};

export default HelpSectionItem;
