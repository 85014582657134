import React, { useState } from "react";
import { Stack, Accordion } from "react-bootstrap";
import TicketItem from "./TicketItem";
import BrandButtonAnimated from "../BrandButtonAnimated";
import styles from "./tickets.module.css";

const TicketsListWithMoreButton = ({ listTitle, showTicketsList = [], variant }) => {
  const [visibleCount, setVisibleCount] = useState(10);

  const handleMoreClick = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  console.log("TicketsList", showTicketsList.slice(0, visibleCount));

  return (
    <Stack gap={1} className="m-3">
      <h5 className={styles.listTitle}>{listTitle}</h5>
      <Accordion defaultActiveKey="0">
        <Stack gap={3}>
          {showTicketsList.slice(0, visibleCount).map((tk) => (
            <TicketItem key={tk.uuid} eventKey={tk.uuid} tk={tk} variant={variant} />
          ))}
        </Stack>
      </Accordion>
      {visibleCount < showTicketsList.length && <BrandButtonAnimated onClick={handleMoreClick}>More</BrandButtonAnimated>}
    </Stack>
  );
};

export default TicketsListWithMoreButton;
