import React, { useEffect } from "react";
import style from "./index.module.css";
import useThemeStore from "../../store/themeStore";


const ThemeToggle = () => {
  const { isWhiteMode, toggleTheme } = useThemeStore();

  useEffect(() => {
    if (isWhiteMode) {
      document.body.classList.add("white-mode");
    } else {
      document.body.classList.remove("white-mode");
    }
  }, [isWhiteMode]);

  return (
    <div className={style.switchContainer}>
      <label className={style.switch}>
        <input type="checkbox" checked={isWhiteMode} onChange={toggleTheme} />
        <span className={style.slider}>
          <span className={style.iconSun}></span>
          <span className={style.iconMoon}></span>
        </span>
      </label>
    </div>
  );
};

export default ThemeToggle;
