// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminSectionPage_cardContainer__WJasf {
    background-color: white;
    color: black;
}

.AdminSectionPage_cardContainerDark__gSg7u {
    background-color: black;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminSectionPage/index.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".cardContainer {\n    background-color: white;\n    color: black;\n}\n\n.cardContainerDark {\n    background-color: black;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `AdminSectionPage_cardContainer__WJasf`,
	"cardContainerDark": `AdminSectionPage_cardContainerDark__gSg7u`
};
export default ___CSS_LOADER_EXPORT___;
