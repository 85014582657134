export const links = {
  home: "/",
  chat: "/chat",
  tickets: "/tickets",
  createTicket: "/tickets/create",
  singleTicket: "/tickets/:ticketId",
  helpcenter: "/helpcenter",
  helpcenterSections: "/helpcenter/:sectionSlug",
  helpcenterApps: "/helpcenter/:sectionSlug/:appSlug",
  helpcenterArticles: "/helpcenter/:sectionSlug/:appSlug/:articleSlug",
  articles: "/articles",
  employee: "/employee",
  dashboard: "/dashboard",
  tools: "/tools",
  qrCodeGen: "/tools/qr",
  signatureGen: "/tools/sign",
  settings: "/settings",
  pageNotFound: "*",
};

export default links;
