// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoScreen_logoScreen__cXzZ4 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.LogoScreen_logoScreen__cXzZ4.LogoScreen_fadeIn__ays\\+A {
  opacity: 1;
}

.LogoScreen_logoScreen__cXzZ4.LogoScreen_fadeOut__mZsPv {
  opacity: 0;
}

.LogoScreen_logo__PuVlW {
  width: 100%;
  height: auto;
  max-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LogoScreen/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".logoScreen {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: transparent;\n  z-index: 9999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: opacity 0.5s ease-in-out;\n  opacity: 0;\n}\n\n.logoScreen.fadeIn {\n  opacity: 1;\n}\n\n.logoScreen.fadeOut {\n  opacity: 0;\n}\n\n.logo {\n  width: 100%;\n  height: auto;\n  max-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoScreen": `LogoScreen_logoScreen__cXzZ4`,
	"fadeIn": `LogoScreen_fadeIn__ays+A`,
	"fadeOut": `LogoScreen_fadeOut__mZsPv`,
	"logo": `LogoScreen_logo__PuVlW`
};
export default ___CSS_LOADER_EXPORT___;
