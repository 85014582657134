import style from "./style.module.css";
import { Container } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import useThemeStore from "../../store/themeStore";

library.add(fas);

const InputField = ({ label, value = "", icon = <></>, onChange = () => {}, extended = false, placeholder = "" }) => {
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);

  return (
    <Container fluid className={style.wrapper}>
      <div className={style.iconPlaceholder}>
        <FontAwesomeIcon icon={`fa-solid ${icon}`} size="2x" />
      </div>
      <div className={`${style.inputContainer} ${isWhiteMode ? style.inputContainerWhiteMode : ""}`}>
        <label htmlFor="name" className={`${style.label} ${isWhiteMode ? style.labelWhiteMode : ""}`}>
          {label}
        </label>
        {extended ? (
          <textarea
            rows={4}
            id={`${label}_input`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={`${style.input} ${isWhiteMode ? style.inputWhiteMode : ""}`}
          />
        ) : (
          <input
            type="text"
            id={`${label}_input`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className={`${style.input} ${isWhiteMode ? style.inputWhiteMode : ""}`}
          />
        )}
      </div>
    </Container>
  );
};

export default InputField;
