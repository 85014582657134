import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import Spinner from "react-bootstrap/Spinner";

import useUserStore from "../../store/useUserStore";
import { fetchArticles } from "../../handlers/strapi";
import HelpSectionItem from "../../components/HelpCenter/HelpSectionItem";
import HelpArticleItem from "../../components/HelpCenter/HelpArticleItem";
import HelpAppItem from "../../components/HelpCenter/HelpAppItem";
import BrandButton from "../../components/BrandButton";
import PaginationBar from "../../components/PaginationBar";
import SearchBar from "../../components/SearchBar";
import links from "../../router/links";

const HelpCenterPage = () => {
  console.log("HelpCenterPage SHOW");
  const { sectionSlug, appSlug, articleSlug } = useParams();
  const { helpSections } = useUserStore();
  const [articles, setArticle] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageCount: 1,
    total: 0,
  });

  useEffect(() => {
    if (
      articles?.meta?.pagination &&
      articles.meta.pagination.pageCount !== pagination.pageCount
    ) {
      setPagination({
        page: articles.meta.pagination.page,
        pageSize: articles.meta.pagination.pageSize,
        pageCount: articles.meta.pagination.pageCount,
        total: articles.meta.pagination.total,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles?.meta?.pagination]);

  useEffect(() => {
    const fetch = async () => {
      setArticle(
        await fetchArticles(
          appSlug,
          pagination.page,
          pagination.pageSize,
          searchString
        )
      );
    };

    setArticle([]);
    console.log("Fetching articles", appSlug, pagination, searchString);
    if (appSlug) {
      fetch();
    }
  }, [appSlug, pagination, searchString]);

  const handleSearch = (query) => {
    setSearchString(query);
  };

  const handlePageChange = (newPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage,
    }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newPageSize,
    }));
  };

  const navigate = useNavigate();

  if (articleSlug) {
    return <p>Article</p>;
  } else if (appSlug && !articleSlug) {
    return (
      <Container fluid className="p-4">
        <Row>
          <Col xs={12} md={6} className="text-center">
            <BrandButton
              className="mb-3"
              color="transparent"
              onClick={() => navigate(links.helpcenter)}
            >
              <ArrowLeftCircle /> Back
            </BrandButton>
          </Col>
          <Col xs={12} md={6}>
            <SearchBar onSearch={handleSearch} className="mb-3" />
          </Col>
        </Row>
        {articles?.data?.length > 0 ? (
          <>
            {pagination.pageCount > 1 && (
              <PaginationBar
                totalCount={pagination.total}
                pageSize={pagination.pageSize}
                siblingCount={1}
                currentPage={pagination.page}
                onPageChange={handlePageChange}
                setPageSize={handlePageSizeChange}
                className="mb-3"
              />
            )}
            {articles?.data?.map((article) => (
              <HelpArticleItem key={article.id} article={article.attributes} />
            ))}
            {pagination.pageCount > 1 && (
              <PaginationBar
                totalCount={pagination.total}
                pageSize={pagination.pageSize}
                siblingCount={1}
                currentPage={pagination.page}
                onPageChange={handlePageChange}
                setPageSize={handlePageSizeChange}
                className="m-3"
              />
            )}
          </>
        ) : (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </Container>
    );
  } else if (sectionSlug && !appSlug) {
    return (
      <Container fluid className="m-3">
        <Row>
          {helpSections
            .find((item) => item.attributes.slug === sectionSlug)
            ?.attributes.programs.data?.map((app, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-2">
                <HelpAppItem key={app.id} app={app.attributes} />
              </Col>
            ))}
        </Row>
      </Container>
    );
  } else {
    return (
      <>
        {helpSections?.map((item) => (
          <HelpSectionItem key={item.id} section={item.attributes} />
        ))}
      </>
    );
  }
};

export default HelpCenterPage;
