import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";

import useTickets from "../../hooks/useTickets";
import TicketsListWithMoreButton from "./TicketsListWithMoreButton";
import useUserStore from "../../store/useUserStore";

const TicketsList = () => {
  const { userTickets } = useUserStore();
  const [openTicketsList, setOpenTicketsList] = useState([]);
  const [closedTicketsList, setClosedTicketsList] = useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);

  const { fetchTickets, isloading } = useTickets();

  useEffect(() => {
    const fetch = async () => {
      await fetchTickets();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const priority = {
      low: 1,
      medium: 2,
      high: 3,
      critical: 4,
    };

    if (isloading === false && userTickets?.length > 0) {
      // TODO: Нахуя?
      const selectedTickets = selectedUserIndex !== null ? [userTickets[selectedUserIndex]] : userTickets;
      setOpenTicketsList(
        userTickets
          .flatMap((tk) => tk.open)
          .sort((a, b) => {
            if (priority[b.priority] !== priority[a.priority]) {
              return priority[b.priority] - priority[a.priority];
            } else {
              return new Date(a.create_time) - new Date(b.create_time);
            }
          })
      );
      setClosedTicketsList(userTickets.flatMap((tk) => tk.closed).sort((a, b) => new Date(b.close_time) - new Date(a.close_time)));
    }
  }, [isloading, userTickets, selectedUserIndex]);

  if (isloading) {
    return (
      <Container fluid className="text-center p-5">
        <Spinner />
      </Container>
    );
  }

  if (userTickets?.length === 0) {
    return (
      <Container fluid className="text-center p-5">
        No tickets
      </Container>
    );
  }

  return (
    <>
      {userTickets?.length > 1 ? (
        <div>
          {userTickets.map((user, index) => (
            <button key={index} onClick={() => setSelectedUserIndex(index)}>
              {user.name}
            </button>
          ))}
        </div>
      ) : (
        <>
          {openTicketsList.length > 0 && <TicketsListWithMoreButton listTitle="Opened tickets:" showTicketsList={openTicketsList} />}
          {closedTicketsList.length > 0 && (
            <TicketsListWithMoreButton listTitle="Closed tickets:" showTicketsList={closedTicketsList} variant="closed" />
          )}
        </>
      )}
    </>
  );
};

export default TicketsList;
