import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ButtonGroup, Container } from "react-bootstrap";
import { PeopleFill, CardList, Tools } from "react-bootstrap-icons";

import pcIcon from "../../static/images/pc.svg";
import chatIcon from "../../static/images/chat.svg";

import styles from "./index.module.css";
import links from "../../router/links";
import useUserStore from "../../store/useUserStore.js";
import useThemeStore from "../../store/themeStore.js";
import ThemeToggle from "../ThemeToggle";

export default function SideMemu() {
  const { userProfile } = useUserStore();
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);
  const [activePage, setActivePage] = useState("help");
  const [showToolsSubMenu, setShowToolsSubMenu] = useState(false);

  const handleNavClick = (page) => {
    // TODO: Переделать через useParams
    setActivePage(page);
  };

  const toggleSubMenu = () => {
    setShowToolsSubMenu((prevState) => !prevState);
  };

  return (
    <Container className="m-0 p-0">
      <ButtonGroup vertical aria-label="Menu" className={isWhiteMode ? `${styles.sideMenu}` : `${styles.sideMenuDark}`}>
        {userProfile && (
          <>
            <NavLink
              to={links.chat}
              className={
                isWhiteMode
                  ? `${styles.sideBtn} ${activePage === "chat" ? styles.active : ""}`
                  : `${styles.sideBtnDark} ${activePage === "chat" ? styles.activeDark : ""}`
              }
              onClick={() => handleNavClick("chat")}
            >
              <img src={chatIcon} alt="Chat help" width={20} height={20} /> Chat help
            </NavLink>
            <NavLink
              to={links.tickets}
              className={
                isWhiteMode
                  ? `${styles.sideBtn} ${activePage === "tickets" ? styles.active : ""}`
                  : `${styles.sideBtnDark} ${activePage === "tickets" ? styles.activeDark : ""}`
              }
              onClick={() => handleNavClick("tickets")}
            >
              <CardList alt="Tickets" width={20} height={20} />
              {userProfile.isAdmin ? "Dashboard" : "My tickets"}
            </NavLink>
            {userProfile?.myEmployees?.length > 0 && (
              <NavLink
                to={links.employee}
                className={
                  isWhiteMode
                    ? `${styles.sideBtn} ${activePage === "employee" ? styles.active : ""}`
                    : `${styles.sideBtnDark} ${activePage === "employee" ? styles.activeDark : ""}`
                }
                onClick={() => handleNavClick("employee")}
              >
                <PeopleFill alt="Employees" width={20} height={20} />
                My Employees
              </NavLink>
            )}
          </>
        )}

        <>
          <NavLink to="#" className={isWhiteMode ? `${styles.sideBtn}` : `${styles.sideBtnDark}`} onClick={toggleSubMenu}>
            <Tools alt="Tools" width={20} height={20} /> Tools
          </NavLink>

          {showToolsSubMenu && (
            <div className={styles.subMenu}>
              <NavLink
                to={links.qrCodeGen}
                className={
                  isWhiteMode
                    ? `${styles.subMenuBtn} ${activePage === "qr-gen" ? styles.subActive : ""}`
                    : `${styles.subMenuBtnDark} ${activePage === "qr-gen" ? styles.subActiveDark : ""}`
                }
                onClick={() => handleNavClick("qr-gen")}
              >
                QR Gen.
              </NavLink>
              <NavLink
                to={links.signatureGen}
                className={isWhiteMode ? `${styles.subMenuBtn}` : `${styles.subMenuBtnDark}`}
                onClick={() => handleNavClick("sign-gen")}
              >
                Signature Gen.
              </NavLink>
            </div>
          )}
        </>
        <NavLink
          to={links.helpcenter}
          className={
            isWhiteMode
              ? `${styles.sideBtn} ${activePage === "help" ? styles.active : ""}`
              : `${styles.sideBtnDark} ${activePage === "help" ? styles.activeDark : ""}`
          }
          onClick={() => handleNavClick("help")}
        >
          <img src={pcIcon} alt="Help Center" width={20} height={20} /> Help Center
        </NavLink>
        <div className={styles.themeToggle}>
          <ThemeToggle />
        </div>
      </ButtonGroup>
    </Container>
  );
}
