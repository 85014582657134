import React, { useEffect } from "react";
import { MicFill, MicMute } from "react-bootstrap-icons";
import { Button, Container } from "react-bootstrap";

import styles from "./index.module.css";

import startSoundSource from "../../static/audio/start.mp3";
import stoptSoundSource from "../../static/audio/stop.mp3";

import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import useGPTChat from "../../hooks/useOpenAI";

const startSound = new Audio(startSoundSource);
const stopSound = new Audio(stoptSoundSource);

const VoiceRecorder = ({ onTranscription }) => {
  const recorderControls = useVoiceVisualizer({
    onStartRecording: () => startSound.play(),
    onStopRecording: () => stopSound.play(),
  });

  const { recognizeSpeech } = useGPTChat();
  const { audioRef, recordedBlob, error, isRecordingInProgress, startRecording, stopRecording } = recorderControls;

  useEffect(() => {
    const recognoze = async () => {
      onTranscription(await recognizeSpeech(recordedBlob));
    };

    if (recordedBlob && !isRecordingInProgress) {
      recognoze();
    }
    // eslint-disable-next-line
  }, [recordedBlob, error, isRecordingInProgress]);

  useEffect(() => {
    if (!error) return;
    console.error("VoiceRecord componment error:", error);
  }, [error]);

  return (
    <Container fluid>
      <VoiceVisualizer
        audioRef={audioRef}
        fullscreen={true}
        isRecordingInProgress={false}
        onlyRecording={true}
        isDefaultUIShown={false}
        isProgressIndicatorTimeShown={false}
        isAudioProcessingTextShown={false}
        isControlPanelShown={false}
        controls={recorderControls}
        height={45}
        speed={-15}
        mainContainerClassName={styles.voiceBar}
      />
      <Button variant="flat">
        {isRecordingInProgress ? <MicMute size={24} onClick={stopRecording} /> : <MicFill onClick={startRecording} size={24} />}
      </Button>
    </Container>
  );
};

export default VoiceRecorder;
