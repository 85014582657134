import React, { useState } from "react";
import remarkGfm from "remark-gfm";

import { Row, Stack, Collapse } from "react-bootstrap";

import styles from "./index.module.css";
import MarkdownContent from "../../MarkdownContent";

const HelpArticleItem = ({ article }) => {
  const { slug, title, text, subtitle } = article;
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="horizontal" className={styles.card} gap={5} key={slug} onClick={() => setOpen(!open)}>
      <Row className={styles.content}>
        <h5 className="">{title}</h5>
        <MarkdownContent>{subtitle ?? `${text.substring(0, 55)}${text.length > 55 ? "..." : ""}`}</MarkdownContent>
        <Collapse
          in={open}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className={styles.customCardDescription}>
            <hr />
            <MarkdownContent remarkPlugins={[remarkGfm]}>{text}</MarkdownContent>
          </div>
        </Collapse>
      </Row>
    </Stack>
  );
};

export default HelpArticleItem;
