import React, { useEffect, useRef, useState } from "react";
import { Accordion, Col, Form, Row, Stack, useAccordionButton, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ArrowRightCircleFill, Paperclip } from "react-bootstrap-icons";
import TextareaAutosize from "react-textarea-autosize";

import { dateToString } from "../../tools";
import useTickets from "../../hooks/useTickets";
import useUserStore from "../../store/useUserStore";

import styles from "./tickets.module.css";

const TicketItem = ({ eventKey, tk: initialTk, variant }) => {
  const { userProfile, userTickets, setUserTickets } = useUserStore();
  const [tk, setTk] = useState(initialTk);
  const [message, setMessage] = useState("");
  const AccordionOnClick = useAccordionButton(eventKey);
  const { closeTicket, addTicketAnswer, updatePriority } = useTickets();
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const closed = variant?.toLowerCase() === "closed";

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [tk.conversations]);

  const handleUploadFile = async (e) => {
    e.preventDefault();
    setFiles([...files, "asd"]);
    // const file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("file", file);
    // const response = await fetch("/api/upload", {
    //   method: "POST",
    //   body: formData,
    // });
    // const data = await response.json();
    // console.log(data);
  };

  const handleAnswerSubmit = async (e) => {
    e.preventDefault();
    await addTicketAnswer(tk.uuid, message);
    setTk((prevTk) => ({
      ...prevTk,
      conversations: [
        ...prevTk.conversations,
        {
          uuid: `msg-${new Date().getTime()}`,
          ticketUuid: tk.uuid,
          userId: userProfile.id,
          userName: userProfile.name,
          message,
          files: [],
        },
      ],
    }));
    setMessage("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      e.preventDefault();
      handleAnswerSubmit(e);
    }
  };

  const handlePriorityChange = async (newPriority) => {
    updatePriority(tk.uuid, newPriority);
    setTk((prevTk) => ({
      ...prevTk,
      priority: newPriority,
    }));
  };

  const handleTicketClose = (e) => {
    e.stopPropagation();
    const newSet = userTickets.map((ticket) => (ticket.uuid === tk.uuid ? { ...ticket, state: "cancelled" } : ticket));
    setUserTickets(newSet);
    closeTicket(tk.uuid);
  };

  const ChangePriorityToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={`${styles.priority} ${styles[tk.priority?.toLowerCase()]}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  ChangePriorityToggle.displayName = "ChangePriorityToggle";

  return (
    <Accordion.Item eventKey={tk.uuid} key={tk.uuid} className={`${styles.item} ${closed ? styles.closed : ""}`} onClick={AccordionOnClick}>
      <Row className={styles.header}>
        <Col md={10} className="d-flex align-items-start">
          <span role="button" className="text-start">
            <Row
              className="m-0 p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/tickets/${tk.uuid}`);
              }}
            >
              {tk.title || "Untitled"}
              <span className={styles.date}>{dateToString(tk.createdAt)}</span>
            </Row>
          </span>
        </Col>
        {!closed && (
          <Col md={2} className="d-flex justify-content-end">
            <Stack gap={2} style={{ padding: "10px" }} direction="horizontal">
              <Dropdown
                id={`dropdown-priority-${tk.uuid}`}
                className={`${styles.priority} ${styles[tk.priority?.toLowerCase()]}`}
                onSelect={handlePriorityChange}
                onClick={(e) => e.stopPropagation()}
              >
                <Dropdown.Toggle as={ChangePriorityToggle} id="dropdown-custom-components">
                  {tk.priority.toUpperCase() || "LOW"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="low">Low</Dropdown.Item>
                  <Dropdown.Item eventKey="medium">Medium</Dropdown.Item>
                  <Dropdown.Item eventKey="high">High</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={styles.closeBtn} onClick={handleTicketClose}>
                {closed ? tk.state : "Close ticket"}
              </div>
            </Stack>
          </Col>
        )}
      </Row>
      <Accordion.Body className={styles.itemBody}>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {tk.conversations?.map((msg) => {
            // const isUser = userProfile.id === msg.userId; 
            const isUser = true; 
            console.log("isUser is ->",isUser)
            return (
              <div key={msg.uuid} className="d-flex flex-column flex-start">
                <span
                  className={`${isUser ? "text-start" : "text-end"}`}
                  style={{
                    color: isUser ? "var(--brand-success-color)" : "var(--brand-warning-color)",
                  }}
                >
                  {(isUser && userProfile.name) || msg.userName || msg.userId}
                </span>
                <span
                  className={`${isUser ? "text-start" : "text-end"} text-nowrap text-start p-0 m-0`}
                  style={{ fontSize: "0.6rem", letterSpacing: "1px" }}
                >
                  {dateToString(msg.createdAt)}
                </span> 
                <p className={`${isUser ? "text-start" : "text-end"}`}>{msg.message || "No message"}</p>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
        {!closed && (
          <>
            <Form onSubmit={handleAnswerSubmit}>
              <Form.Group className="mb-3 relative" controlId="formBasicMessage">
                <TextareaAutosize
                  className={`form-control ${styles.textarea}`}
                  minRows={1}
                  placeholder="Text message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={handleKeyPress}
                  required
                />
                <button className={styles.Paperclip} onClick={handleUploadFile}>
                  <Paperclip
                    style={{
                      color: files.length > 0 ? "var(--brand-success-color)" : "",
                    }}
                  />
                  {files.length > 0 && (
                    <span
                      className={styles.fileCount}
                      style={{
                        color: files.length > 0 ? "var(--brand-success-color)" : "",
                      }}
                    >
                      {files.length}
                    </span>
                  )}
                </button>
                <button className={styles.sendBtn} onClick={(e) => e.stopPropagation()} type="submit">
                  <ArrowRightCircleFill />
                </button>
              </Form.Group>
            </Form>
          </>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TicketItem;
