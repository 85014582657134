import React from "react";
import style from "./index.module.css";

import useThemeStore from "../../store/themeStore";

const AdminDashboardPage = () => {
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);

  return (
    <div className={ isWhiteMode ? style.cardContainer : style.cardContainerDark}>
      Admin Page
    </div>
  );
};

export default AdminDashboardPage;
