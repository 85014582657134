import React, { useEffect } from "react";

import { Router } from "./router";
import useUserStore from "./store/useUserStore";
import { fetchSections } from "./handlers/strapi";

import "./styles/App.css";

export default function App() {
  const { setHelpSections, fetchUserProfile } = useUserStore();

  useEffect(() => {
    fetchUserProfile();
    const intervalFetchUser = setInterval(() => {
      fetchUserProfile();
    }, 10 * 1000);
    return () => clearInterval(intervalFetchUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initFetch = async () => {
      setHelpSections(await fetchSections());
    };

    initFetch();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App" data-bs-theme="dark">
      <Router />
    </div>
  );
}
