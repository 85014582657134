import React, { useState } from "react";
import { Stack, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import useUserStore from "../../store/useUserStore";
import BrandButton from "../../components/BrandButton";
import BrandButtonAnimated from "../../components/BrandButtonAnimated";
import TicketsList from "../../components/Ticket/TicketsList";
import NewTicketForm from "../../components/Ticket/NewTicketForm";
import SingleTicket from "../../components/Ticket/SingleTicket";

const TicketsPage = ({ create = false }) => {
  const navigate = useNavigate();
  const { userProfile } = useUserStore();
  const { ticketId } = useParams();
  const [showCreateForm, setShowCreateForm] = useState(create);

  if (ticketId) {
    return <SingleTicket ticketId={ticketId} />;
  } else if (showCreateForm) {
    return <NewTicketForm onCancel={() => setShowCreateForm(false)} />;
  } else {
    return (
      <Container fluid>
        <Stack direction="horizontal" gap={5} className="d-flex w-100 justify-content-center p-2">
          <span>
            Tickets from <span className="fw-normal">{userProfile?.name}</span>
          </span>
          <BrandButton onClick={() => setShowCreateForm(true)}>Create a new ticket</BrandButton>
          <Stack gap={2}>
            {userProfile?.isManager && (
              <BrandButtonAnimated onClick={() => setShowCreateForm(false)} style={{ fontSize: "0.7rem", width: "100%" }}>
                Show history
              </BrandButtonAnimated>
            )}
            {userProfile?.myEmployees?.length > 0 && (
              <BrandButtonAnimated onClick={() => navigate("/employee")} style={{ fontSize: "0.5rem", width: "100%" }}>
                Show history
              </BrandButtonAnimated>
            )}
          </Stack>
        </Stack>
        <TicketsList />
      </Container>
    );
  }
};

export default TicketsPage;
