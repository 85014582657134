import React, { useState } from "react";

import { BoxArrowLeft, PersonCircle } from "react-bootstrap-icons";
import { Image, Stack, Dropdown } from "react-bootstrap";

import { MicrosoftLoginButton } from "./MicrosoftLogin";
import useUserStore from "../store/useUserStore";
import useUserData from "../hooks/useUserData";
import useThemeStore from "../store/themeStore";

export const ProfileButton = () => {
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);
  const { userProfile } = useUserStore();
  const { logoutUser } = useUserData();

  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  CustomToggle.displayName = "CustomToggle";

  return userProfile ? (
    <Dropdown show={showDropdown} onToggle={handleToggle} className="d-inline mx-2">
      <Dropdown.Toggle as={CustomToggle} id="profile-dropdown-btn">
        {userProfile?.avatar ? (
          <Image src={userProfile.avatar} roundedCircle width="30" height="30" />
        ) : (
          <PersonCircle style={ isWhiteMode ? {fill: 'black'} : {fill: 'white'}} width="30" height="30" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu align={{ xs: "start" }}>
        <Dropdown.Item>{userProfile?.name}</Dropdown.Item>
        {/* <Dropdown.Item>
          <Stack direction="horizontal" gap={2}>
            <Spinner animation="grow" variant="danger" size="sm" />
            Yout have 1 message
          </Stack>
        </Dropdown.Item> */}
        {/* <Dropdown.Divider />
        {accounts.map((acc) => (
          <Dropdown.Item
            key={acc.homeAccountId}
            onClick={async () => await switchUser(acc)}
          >
            <Stack direction="horizontal" gap={2}>
              <PersonCheck />
              <Col className="text-start">
                <Row className="p-0 m-0 text-nowrap">{acc.name}</Row>
                <Row
                  className="p-0 m-0 fw-lighter font-size-sm text-nowrap"
                  style={{
                    fontSize: "0.7rem",
                    color: "gray",
                    letterSpacing: "0.1rem",
                  }}
                >
                  {acc.username}
                </Row>
              </Col>
            </Stack>
          </Dropdown.Item>
        ))} */}
        {/* <Dropdown.Item onClick={loginUser}>
          <Stack direction="horizontal" gap={2}>
            <PersonPlus />
            Sign in with a different account
          </Stack>
        </Dropdown.Item> */}
        <Dropdown.Divider />
        <Dropdown.Item onClick={logoutUser}>
          <Stack direction="horizontal" gap={2}>
            <BoxArrowLeft />
            Sign out
          </Stack>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <MicrosoftLoginButton />
  );
};
