// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VoiceRecorder_voiceBar__0SDTK {
  position: relative;
  width: 45px;
  right: 40px;
  top: 43px;
  height: 45px;
}
`, "",{"version":3,"sources":["webpack://./src/components/VoiceRecorder/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,SAAS;EACT,YAAY;AACd","sourcesContent":[".voiceBar {\n  position: relative;\n  width: 45px;\n  right: 40px;\n  top: 43px;\n  height: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"voiceBar": `VoiceRecorder_voiceBar__0SDTK`
};
export default ___CSS_LOADER_EXPORT___;
