// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThemeToggle_switch-container__Udfid {
  display: flex;
  align-items: center;
}

.ThemeToggle_switch__Gsmi9 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.ThemeToggle_switch__Gsmi9 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ThemeToggle_slider__YaD0i {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  background-color: #222222;
  transition: 0.4s;
  border-radius: 34px;
}

.ThemeToggle_slider__YaD0i:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .ThemeToggle_slider__YaD0i {
    background-color: #ccc;
}

input:checked + .ThemeToggle_slider__YaD0i:before {
  transform: translateX(26px);
}

.ThemeToggle_icon__eKt31 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.ThemeToggle_icon__eKt31.ThemeToggle_sun__1X2gw {
  left: 8px;
}

.ThemeToggle_icon__eKt31.ThemeToggle_moon__76MEw {
  right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ThemeToggle/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;;EAET,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".switch-container {\n  display: flex;\n  align-items: center;\n}\n\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 60px;\n  height: 34px;\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  \n  background-color: #222222;\n  transition: 0.4s;\n  border-radius: 34px;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  height: 26px;\n  width: 26px;\n  left: 4px;\n  bottom: 4px;\n  background-color: white;\n  transition: 0.4s;\n  border-radius: 50%;\n}\n\ninput:checked + .slider {\n    background-color: #ccc;\n}\n\ninput:checked + .slider:before {\n  transform: translateX(26px);\n}\n\n.icon {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 20px;\n  height: 20px;\n}\n\n.icon.sun {\n  left: 8px;\n}\n\n.icon.moon {\n  right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch-container": `ThemeToggle_switch-container__Udfid`,
	"switch": `ThemeToggle_switch__Gsmi9`,
	"slider": `ThemeToggle_slider__YaD0i`,
	"icon": `ThemeToggle_icon__eKt31`,
	"sun": `ThemeToggle_sun__1X2gw`,
	"moon": `ThemeToggle_moon__76MEw`
};
export default ___CSS_LOADER_EXPORT___;
