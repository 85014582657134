import React from "react";
import { useNavigate } from "react-router-dom";

import BrandButtonAnimated from "../../BrandButtonAnimated";

const HelpAppItem = ({ app }) => {
  const navigate = useNavigate();

  return (
    <div>
      <BrandButtonAnimated onClick={() => navigate(`${app.slug}`)}>{app.Name}</BrandButtonAnimated>
    </div>
  );
};

export default HelpAppItem;
